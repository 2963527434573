<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-data-table
      :headers="headers"
      :items="listProducts"
      :loading="isLoadingProducts"
      :items-per-page="30"
      :footer-props="{ 'items-per-page-options': [5, 30, 50] }"
    >
      <template v-slot:item.image="{ item }">
        <v-img
          v-if="item.image"
          :src="item.image.url"
          max-height="50"
          max-width="50"
        />
        <v-img
          v-else
          src="@/assets/product.png"
          max-height="50"
          max-width="50"
        />
      </template>
      <template v-slot:item.order_status="{ item }">
        <v-chip :color="getColor(item.order_status)" dark>
          {{ item.order_status }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="showItem(item)"> mdi-eye </v-icon>
      </template>
      <template v-slot:no-data>
        {{ $t("no_data_available") }}
      </template>
      <template v-slot:top>
        <v-toolbar flat class="pr-0">
          <v-toolbar-title>{{ $t("products") }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-1"
            color="primary"
            @click="exportOutboundList(options)"
            :loading="is_loading_detail_csv"
            :disabled="is_loading_detail_csv"
          >
            <v-icon color="white" class="mr-2"> mdi-filter </v-icon>
            {{ $t("export_detail_csv") }}
          </v-btn>

          <v-btn
            color="primary"
            @click="getCSV(options, false)"
            :loading="is_loading_csv"
            :disabled="is_loading_csv"
          >
            <v-icon color="white" class="mr-2"> mdi-filter </v-icon>
            {{ $t("export_result_csv") }}
          </v-btn>
        </v-toolbar>
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <v-autocomplete
              clearable
              v-model="options.hub_id"
              :items="hubs"
              item-text="name"
              item-value="id"
              :label="$t('hub')"
              :search-input.sync="search_hub"
              :loading="isLoadingHubs"
              no-filter
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="options.start_date"
                  :label="$t('start_date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="options.start_date"
                no-title
                color="green lighten-1"
                header-color="green lighten-1"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-menu
              ref="menu"
              v-model="menu_end"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="options.end_date"
                  :label="$t('end_date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="options.end_date"
                no-title
                color="premary"
                @input="menu_end = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="2" md="2">
            <v-btn
              block
              color="primary"
              class="pr-2"
              @click="filterOutbound"
              :loading="isLoadingProducts"
              :disabled="isLoadingProducts"
            >
              {{ $t("filter") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";
import accountingService from "@/store/services/accounting-service";

export default {
  components: {},

  computed: {
    ...mapGetters({
      isLoadingHubs: "hubs/isLoadingHubs",
      hubs: "hubs/listHubs",

      isLoadingProducts: "accounting/isLoadingData",
      listProducts: "accounting/listOutbounds",
    }),
  },

  watch: {
    search_hub: debounce(function (search) {
      if (this.isLoadingHubs) return;

      this.$store.dispatch("hubs/list", {
        store_id: this.store_id,
        itemsPerPage: 30,
        search,
      });
    }, 1000),
  },

  data() {
    return {
      is_loading_csv: false,
      is_loading_detail_csv: false,
      menu: false,
      menu_end: false,
      options: { type: "outbound" },
      search_provider: "",
      search_hub: "",

      headers: [
        {
          text: this.$t("sku"),
          align: "start",
          sortable: false,
          value: "sku",
        },
        {
          text: this.$t("product_name"),
          align: "start",
          value: "name",
        },
        {
          text: this.$t("quantity"),
          value: "order_qte",
        },

        {
          text: this.$t("purchase_price_ht"),
          value: "purchase_price_ht",
        },
        {
          text: this.$t("purchase_tva"),
          value: "purchase_tva",
        },

        {
          text: this.$t("price_ht"),
          value: "price_ht",
        },
        {
          text: this.$t("tva"),
          value: "tva",
        },

        {
          text: this.$t("order_tracking_number"),
          value: "order_tracking_number",
        },
        {
          text: this.$t("date"),
          value: "order_date",
        },
        {
          text: this.$t("status"),
          value: "order_status",
        },
        {
          text: this.$t("actions"),
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    showItem(item) {
      this.$store.dispatch("products/openShowForm", item);
    },

    editItem(item) {
      this.$store.dispatch("products/openEditForm", item);
    },

    deleteItem(item) {
      this.$store.dispatch("products/openDeleteForm", item);
    },

    getColor(status) {
      if (status === "delivered") return "green";
      else return "red";
    },

    async getCSV(option, details = true) {
      details
        ? (this.is_loading_detail_csv = true)
        : (this.is_loading_csv = true);
      this.options.detail = details;
      await this.$store
        .dispatch("accounting/downloadCSV", option)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Outbound_products.csv");
          document.body.appendChild(link);
          // window.open(link);
          link.click();
          details
            ? (this.is_loading_detail_csv = false)
            : (this.is_loading_csv = false);
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.statusText);
          this.is_loading_invoice = false;
        });
    },

    async exportOutboundList(option) {
      this.is_loading_detail_csv = true;
      await accountingService
        .exportOutboundList(option)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Outbound_list.csv");
          document.body.appendChild(link);
          // window.open(link);
          link.click();
          this.is_loading_detail_csv = false;
        })
        .catch((error) => {
          this.is_loading_detail_csv = false;
          this.$store.dispatch("alerts/error", error.response?.statusText);
        });
    },

    async filterOutbound() {
      await this.$store.dispatch("accounting/listOutbounds", {
        type: "outbound",
        ...this.options,
      });
    },
  },
};
</script>
<style>
.v-data-table .v-toolbar__content {
  padding-right: 0;
}
</style>
